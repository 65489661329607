import * as React from "react";
import {message, Space, Table, Tabs} from "antd";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import TableEmpty from "../../../components/table/TableEmpty";
import styled from "@emotion/styled";
import {useRequest} from "ahooks";
import jsonRequest from "../../../utils/request/jsonRequest";
import {useEffect, useState} from "react";
import auth from "../../../utils/auth";
import * as Moment from "moment"
import ProjectOptions from "../../../utils/options/projectOptions";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "gatsby";


const Container = styled.div`
  padding: 12px 24px 24px;
  margin: 0 auto;
  //width: ${(props: any) => props.width}px;
  min-height: calc(100vh - 252px);
  flex: 1;
  background: #ffffff;
  border-radius: 8px;
  
  .ant-tabs-tab {
    font-weight: bold;
    color: rgba(0,0,0,0.4);
  }
  
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgba(0,0,0,0.8) !important;
  }
  .ant-tabs-ink-bar {
    background-color: rgba(0,0,0,0.8);
  }
`

export type ProjectTableProps = {
  type: string;
}

const pageSize = 20

const ProjectTable = (props: ProjectTableProps) => {
  const {type} = props;

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)

  const {run, data = [], loading} = useRequest((page) => {
    return jsonRequest(`/account/project/${type}?pageSize=${pageSize}&current=${page}`)
    .then(rsp => {
      console.log(rsp.data)
      setTotal(rsp.data.total)
      setCurrent(page)
      return rsp.data.data
    }).catch(message.error)
  }, {
    manual: true,
  })

  useEffect(() => {
    run(1)
  }, [type])

  return (
    <Table
      columns={[
        {
          title: '项目名称',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: '项目编号',
          key: 'no',
          dataIndex: 'no',
        },
        {
          title: '交付时间',
          key: 'date',
          render: (_, {endTime}) => Moment(endTime).format("YYYY年MM月DD日")
        },
        {
          title: '状态',
          key: 'status',
          render: (_, {stage, status, commentStatus, isEditing}) => {
            let statusText = ProjectOptions.status[stage][status]
            if (isEditing) {
              statusText = '编辑审核中';
            }
            if (stage === 'finish' && status === 'running') {
              statusText = {
                'contractor': '服务方已评价',
                'owner': '项目方已评价',
                'eachOther': '双方已评价',
              }[commentStatus] ?? statusText;
            }
            return `${ProjectOptions.stages[stage] ?? '其他'}-${statusText}`;
          }
        },
        {
          title: '操作',
          key: 'actions',
          width: 55,
          render: (_, {id}) => (
            <Space>
              <Link className='primary' to={`./${type}?id=${id}`}>查看</Link>
            </Space>
          )
        }
      ]}
      rowKey='id'
      size='small'
      {...{loading}}
      dataSource={data}
      pagination={{
        onChange: run,
        total,
        pageSize,
        current,
      }}
      locale={{
        emptyText: <TableEmpty/>
      }}
    />
  )
}

const Project =() => {
  auth.authorize()
  useSelector((s: any) => s)

  const [type, setType] = useState('contractor')

  return (<>
    <PersonalLayout selected='project'>
      <Container>
        <Tabs
          onChange={setType}
          items={[
            {
              key: 'contractor',
              label: '接任务',
              children: (
                <ProjectTable {...{type}}/>
              )
            },
            {
              key: 'owner',
              label: '发布的',
              children: (
                <ProjectTable {...{type}}/>
              )
            },
          ]}
        />
      </Container>
    </PersonalLayout>

  </>)
}
// todo 分页

export default Project
